import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { RegisterFlow, RegisterIntro } from '../../../components/views/layout'
import { Button, LoadingOverlay, Seo } from '../../../components/common'
import { useRegister } from '../../../hooks'
import { Paths } from '../../../constants/structure'

import { registerFields } from '../../../common/utils/register'
import { isEmptyObject } from '../../../common/utils/functions'

const COMPANY_TELEPHONE = process.env.APP_TELEPHONE
const COMPANY_NAME = process.env.APP_COMPANY

const ExistingAccountPage = () => {
   const { formState, reset } = useRegister()

   const handleLoginButtonClick = () => {
      reset()
      navigate(Paths.LOGIN)
   }

   const isInvalid =
      isEmptyObject(formState) || !formState[registerFields.EMAIL]

   useEffect(() => {
      if (isInvalid) {
         navigate(Paths.REGISTER)
      }
   }, [])

   return (
      <RegisterFlow title="Step 2">
         <Seo title="Register - Step 2" />

         {isInvalid ? (
            <LoadingOverlay />
         ) : (
            <>
               <RegisterIntro>
                  We have found that your e-mail address is setup as a{' '}
                  {formState?.roleName} with an existing Enterprise account of{' '}
                  {COMPANY_NAME} under the organization {formState?.company}.
                  <br />
                  <br />
                  Please sign into the system with your password. If you need
                  assistance please call {COMPANY_TELEPHONE} and press 2 for
                  support.
               </RegisterIntro>

               <Button onClick={handleLoginButtonClick}>Sign In</Button>
            </>
         )}
      </RegisterFlow>
   )
}

export default ExistingAccountPage
